import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import "react-datepicker/dist/react-datepicker.css";
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import { HashLink as Link } from 'react-router-hash-link';

import Rezervacija from './Rezervacija.js';
import Navbar from './Navbar.js';
import Izleti from './Izleti.js';
import Footer from "./Footer.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import Plitvice from './plitvice.js';
import Biokovo from './biokovo.js';
import Dubrovnik from './dubrovnik.js';
import Klis from './klis.js';
import Medugorje from './medugorje.js';
import Mostar from './mostar.js';
import Krka from './krka.js';
import Zadar from './zadar.js';
import Faq from './faq.js';
import Covid from './covid.js';
import Kontakt from './kontakt.js';
import Partner from './partner.js';

import Informacije from './informacije.js';
import Uvjeti from './uvjeti.js';

import ScrollToTop from './scrollHook.js';

import reportWebVitals from './reportWebVitals';
import Carousel from 'react-bootstrap/Carousel'

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';


// Import translations
import './i18n';
import { withTranslation, useTranslation } from 'react-i18next';

SwiperCore.use([Navigation]);




class OrigHomePage extends React.Component {
  render()
  {
    const { t } = this.props;

    return (
      <div className="container"> 
          <div>
              <img className="logo" src="slike/logo.png" alt="logo"/>
          </div>
        <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
          
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="d-block w-100" src="slike/main.jpeg" alt="First slide"/>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src="slike/main-2.jpg" alt="Second slide"/>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src="slike/main-3.jpg" alt="Third slide"/>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only"></span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only"></span>
          </a>
        </div>

        
     
        <div className="text">
        <Rezervacija params={ this.props.params } />
        <hr className="line"/>
          <h2>{ t('home.about-title') }</h2>
          <hr className="line"/>
          <p>{ t('home.about-text') }</p>
          <hr className="line"/>
          <h2>{ t('home.excursions-title') }</h2>
          <br/>

          <Swiper 

            breakpoints={{ 
              640: {
                width: 640,
                slidesPerView: 2,
              },
              768: {
                width: 768,
                slidesPerView: 3,
              },
            }}
            spaceBetween={50}
            navigation   
          >
              <SwiperSlide><Link className ="link" to="/Krka" ><img className="img-fluid" src="/slike/izleti/sibenik.jpeg" alt="Krka"/>{ t('krka.krka-title') }</Link></SwiperSlide>
              <SwiperSlide><Link className ="link" to="/Medugorje" ><img className="img-fluid" src="/slike/izleti/medugorje.jpeg" alt="Medugorje"/>{ t('medugorje.medugorje-title') }</Link></SwiperSlide>
              <SwiperSlide><Link className ="link" to="/Plitvice" ><img className="img-fluid" src="/slike/izleti/plitvice.jpeg" alt="Plitvice"/>{ t('plitvice.plitvice-title') }</Link></SwiperSlide>
              <SwiperSlide><Link className ="link" to="/Zadar" ><img className="img-fluid" src="/slike/izleti/zadar.jpeg" alt="ZADAR"/>{ t('zadar.zadar-title') }</Link></SwiperSlide>
              <SwiperSlide><Link className ="link" to="/Biokovo" ><img className="img-fluid" src="/slike/izleti/biokovo.jpg" alt="Biokovo"/>{ t('biokovo.biokovo-title') }</Link></SwiperSlide>
              <SwiperSlide><Link className ="link" to="/Mostar" ><img className="img-fluid" src="/slike/izleti/mostar.jpeg" alt="Mostar"/>{ t('mostar.mostar-title') }</Link></SwiperSlide>
              <SwiperSlide><Link className ="link" to="/Klis" ><img className="img-fluid" src="/slike/izleti/klis.jpeg" alt="Klis"/>{ t('klis.klis-title') }</Link></SwiperSlide>
              <SwiperSlide><Link className ="link" to="/Dubrovnik" ><img className="img-fluid" src="/slike/izleti/dubrovnik.jpeg" alt="Dubrovnik"/>{ t('dubrovnik.dubrovnik-title') }</Link></SwiperSlide>
          </Swiper>
          <br/>

          <h2>{ t('home.review-title') }</h2>
          
          <Carousel controls={false} style={{height: '400px', backgroundImage: `url("slike/comment.jpg")`}} >
            <Carousel.Item>
              <div class="carouselComment">
                <p>
                  Split Airport- Dubrovnik 
                </p>
                <p>
                  It was such a pleasure to travel with this company.<br /> Driver Antonio was absolutely magnificent. Definitely recommended.
                </p>
                <p>
                  Meghan H.
                </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="carouselComment">
                <p>
                  Split Airport- Dubrovnik 
                </p>
                <p>
                  Nekoliko puta sam već rezervirao ovaj transfer i svaki put me vozač u odijelu i s mojim imenom na tabletu čekao. <br />Nova i čista vozila. Velika preporuka. 

                </p>
                <p>
                  Ivica J.
                </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="carouselComment">
                <p>
                  Split- Np Krka  
                </p>
                <p>
                  We arrived on the place of pickup and there was a black Mercedes van which drove us there and waited for us. <br />Driver was a very experienced English speaking driver who told us a lot information about Croatia.

                </p>
                <p>
                  Jessica P.
                </p>
              </div>

            </Carousel.Item>

            <Carousel.Item>
              <div class="carouselComment">
                <p>
                  Flughafen Split- Brela 
                </p>
                <p>
                  Es war toll, mit dieser Firma zu reisen. Der Fahrer und das Auto waren  ausgezeichnet.
                </p>
                <p>
                  Jurgen A.
                </p>
              </div>
            </Carousel.Item>
          </Carousel>

          <div className="allcards">
                    <a className="cards" target="_blank" rel="noreferrer" href="https://www.hrvatskitelekom.hr/poslovni/ict/payway"><img src="slike/cards/payway.png" alt="payway"/></a>
                    <a className="cards" target="_blank" rel="noreferrer" href="https://www.visa.com.hr/"><img src="slike/cards/visa.png" alt="visa"/></a>
                    <a className="cards" target="_blank" rel="noreferrer" href="https://www.mastercard.hr/hr-hr.html"><img src="slike/cards/mastercard.png" alt="mastercard"/></a>
                    <a className="cards" target="_blank" rel="noreferrer" href="http://www.maestrocard.com/gateway/index.html"><img src="slike/cards/maestro.png" alt="maestro"/></a>
          </div>

        </div>
      </div>
      );
    }
  }
const HomePage = withTranslation()(OrigHomePage);


function App() {

  const { t } = useTranslation();

  // const { t } = this.props;
    
  const columns = [
    {
        title: t('footer.column_1.title'),
        resources: [
            {
                name: t('footer.column_1.resource_1'),
                link: "/rezervacija"
            }        
        ]
    },
    {
        title: t('footer.column_2.title'),
        resources: [
            {
                name: t('footer.column_2.resource_1'),
                link: "/Informacije"       
            },
            {
                name: t('footer.column_2.resource_2'),
                link: "/partner"
            },
            {
                name: t('footer.column_2.resource_3'),
                link: "/uvjeti"
            }
        ]
    },
    {
        title: t('footer.column_3.title') ,
        resources: [
            {
                name: "info@macitours.hr",
                link: "mailto:info@macitours.hr"
            },
            {
                name: "+385 91 2255539",
                link: "tel:385 91 2255539"
            }
        ]
    }
  ];

  return (
    <div>
      <Navbar />
      <Switch>
        <Route path="/Izleti"><Izleti /></Route>
        <Route path="/Plitvice"><Plitvice /></Route>
        <Route path="/Biokovo"><Biokovo /></Route>
        <Route path="/Dubrovnik"><Dubrovnik /></Route>
        <Route path="/Klis"><Klis /></Route>
        <Route path="/Medugorje"><Medugorje /></Route>
        <Route path="/Mostar"><Mostar /></Route>
        <Route path="/Krka"><Krka /></Route>
        <Route path="/Zadar"><Zadar /></Route>
        <Route path="/Faq"><Faq /></Route>
        <Route path="/Informacije"><Informacije /></Route>
        <Route path="/Uvjeti"><Uvjeti /></Route>
        <Route path="/Covid"><Covid /></Route>
        <Route path="/Kontakt"><Kontakt /></Route>
        <Route path="/Partner"><Partner /></Route>
        <Route path=""><HomePage params={ useLocation().search } /></Route>
      </Switch>

      <Footer title={ t('footer.title') } description={ t('footer.description') }
      columns={columns} backgroundColor="#343a40" fontColor="white" copyright="Maci-tours" />

    </div>
  );
};

// const App = withTranslation()(OrigApp);



ReactDOM.render(
  <Suspense fallback="loading">
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </Suspense>,
  document.getElementById('app')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals(console.log);

