import React from 'react';
import './Navbar.css';

import { NavLink } from "react-router-dom";

import { useTranslation } from 'react-i18next';


function Navbar() {
  const { t, i18n } = useTranslation();

  return (
	
    <nav className ="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <button className ="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className ="navbar-toggler-icon"></span>
      </button>
      <div className ="collapse navbar-collapse" id="navbarNav">
        <ul className ="navbar-nav mx-auto">
          <li className ="nav-item">
            <NavLink className ="nav-link" data-toggle="collapse" data-target="#navbarNav" to="" exact={true} activeClassName="active" >{ t('navbar.home') }</NavLink>
          </li>
          <li className ="nav-item">
            <NavLink className ="nav-link" data-toggle="collapse" data-target="#navbarNav" to="/Faq" activeClassName="active" >{ t('navbar.faq') }</NavLink>
          </li>

          <li className="nav-item dropdown">
            <NavLink className ="nav-link" data-toggle="collapse" data-target="#navbarNav" to="/Izleti" activeClassName="active" >{ t('navbar.excursions') }</NavLink>
          </li>

          <li className ="nav-item">
            <NavLink className ="nav-link" data-toggle="collapse" data-target="#navbarNav" to="/Kontakt" activeClassName="active" >{ t('navbar.contact') }</NavLink>
          </li>

          <li className ="nav-item">
            <NavLink className ="nav-link" data-toggle="collapse" data-target="#navbarNav" to="/Covid" activeClassName="active" >{ t('navbar.covid') }</NavLink>
          </li> 

          <li className ="nav-item">
            <div className ="nav-link">
              <img src="slike/cro.png" data-toggle="collapse" data-target="#navbarNav" alt="croatian" width="40" height="20" onClick={ () => (i18n.changeLanguage('hr')) }></img>
            </div>
          </li>

          <li className ="nav-item">
            <div className ="nav-link">
              <img src="slike/eng.png" data-toggle="collapse" data-target="#navbarNav" alt="english" width="40" height="20" onClick={ () => (i18n.changeLanguage('en-US')) }></img>
            </div>
          </li>


          {/*
           <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#navbar" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              JEZIK
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a className="dropdown-item" href="#navbar">HR</a>
              <a className="dropdown-item" href="#navbar">EN</a>
            </div>
          </li>
          */}


        </ul>    
      </div>
    </nav>
    
  );
}


export default Navbar;
