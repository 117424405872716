import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';

import './i18n';
import { withTranslation } from 'react-i18next';

import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import Tooltip from 'rc-tooltip';


async function phpRequestIzlet(data) {
  const response = await fetch("/contactformizleti.php",
    {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data
    }
  );
  return response.json();
}

class OrigKontaktizleti extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    

    // Config
    const minimumStartDelay = 720;  // u minutama

    this.minStartDate = new Date();
    this.minStartDate.setSeconds(0);
    this.minStartDate.setMinutes(15 * Math.ceil((
      this.minStartDate.getMinutes() + minimumStartDelay) / 15.0)
    );

    this.state = { value: this.minStartDate };
  }

  handleSubmit(e) {
    /**********/
    /* SUBMIT */
    /**********/

    e.preventDefault();

    phpRequestIzlet(
      'email=' + encodeURIComponent(e.target.email.value) +
      '&imePrezime=' + encodeURIComponent(e.target.imePrezime.value) +
      '&date=' + encodeURIComponent(this.state.value) +
      '&Auta=' + encodeURIComponent(e.target.Auta.value) +
      '&paymethod=' + encodeURIComponent(e.target.paymethod.value) +
      '&flightnum=' + encodeURIComponent(e.target.flightnum.value) +
      '&note=' + encodeURIComponent(e.target.note.value) +
      '&pickup=' + encodeURIComponent(e.target.pickup.value) +
      '&excursion=' + encodeURIComponent(e.target.excursion.value)
    )
    .then(data => {
      if (data['status']==='OK') {
        const { t } = this.props;
        
        // Inform user mail was sent
        alert(t('kontaktizleti.kontaktizleti-success-message'));
      } else {
        // Inform user about server error
        alert(data['message']);
      }
    })
    .catch(err => {
      console.error("Error handling won't work with cross-origin");
    });
  }


  render() {

    const { t } = this.props;
      
    const DateTimeButton = React.forwardRef(
      ({ value, onClick }, ref) => (
        <button className="btn btn-primary" onClick={ (e) => {
          e.preventDefault();
          onClick(e);
        }} ref={ref}>
          {value}
        </button>
      ),
    );

    const icons_5 = (
      <div className="carIconList" >
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-suit') }</span> }><Image className="caricon" src="/slike/icons/suit.png" fluid /></Tooltip>
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-wifi') }</span> }><Image className="caricon" src="/slike/icons/wifi.png" fluid /></Tooltip>
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-water') }</span> }><Image className="caricon" src="/slike/icons/water.png" fluid /></Tooltip>
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-plane') }</span> }><Image className="caricon" src="/slike/icons/plane.png" fluid /></Tooltip>
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-cancel') }</span> }><Image className="caricon" src="/slike/icons/cancel.png" fluid /></Tooltip>
      </div>
    );

    const icons_6 = (
      <div className="carIconList" >
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-suit') }</span> }><Image className="caricon" src="/slike/icons/suit.png" fluid /></Tooltip>
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-wifi') }</span> }><Image className="caricon" src="/slike/icons/wifi.png" fluid /></Tooltip>
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-water') }</span> }><Image className="caricon" src="/slike/icons/water.png" fluid /></Tooltip>
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-plane') }</span> }><Image className="caricon" src="/slike/icons/plane.png" fluid /></Tooltip>
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-seats') }</span> }><Image className="caricon" src="/slike/icons/seats.png" fluid /></Tooltip>
        <Tooltip placement="bottom" overlay={ <span>{ t('tooltips.tooltip-cancel') }</span> }><Image className="caricon" src="/slike/icons/cancel.png" fluid /></Tooltip>
      </div>
    );

    return (
        <div className="formaizleti">
      	  <h3 className="reservetitle">{ t('kontaktizleti.kontaktizleti-title') }</h3>
          <Form name="contactformizleti" onSubmit={ this.handleSubmit }>
            <Form.Group >
              <Form.Label>{ t('kontaktizleti.kontaktizleti-mail') }</Form.Label>
              <Form.Control name="email" type="email" placeholder={ t('kontaktizleti.kontaktizleti-mail-txt') } />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>

            <Form.Group >
              <Form.Label>{ t('kontaktizleti.kontaktizleti-pickup') }</Form.Label>
              <Form.Control name="pickup" type="pickup" placeholder={ t('kontaktizleti.kontaktizleti-pickup') } />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>

            <Form.Group >
              <Form.Label>{ t('kontaktizleti.kontaktizleti-name') }</Form.Label>
              <Form.Control type="text" placeholder={ t('kontaktizleti.kontaktizleti-name-txt') } name="imePrezime" />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>

            <Form.Group >
              <Form.Label>{ t('kontaktizleti.kontaktizleti-flightnum') }</Form.Label>
              <Form.Control name="flightnum" type="flightnum" placeholder={ t('kontaktizleti.kontaktizleti-flightnum') } />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>

            <Form.Group >
              <Form.Label>{ t('kontaktizleti.kontaktizleti-note') }</Form.Label>
              <Form.Control name="note" type="note" placeholder={ t('kontaktizleti.kontaktizleti-note') } />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>

            <ToggleButtonGroup vertical name="Auta">
              { t('kontaktizleti.kontaktizleti-vozilo') }

              <ToggleButton variant="dark" name="odabirAuta" value="Peugeot 301">
                <Image className="kontaktIzletiCar" src="/slike/slikeAuta/standardCar.png" fluid />
                <p>Peugeot 301</p> 

                  { icons_5 }

                  <div className="price">
                  <p>{ t('kontaktizleti.kontaktizleti-price') }
                    { {
                      'Međugorje': '€300.00',
                      'Biokovo': '€220.00',
                      'Klis': '€150.00',
                      'Mostar': '€300.00',
                      'Krka': '€180.00',
                      'Dubrovnik': '€270.00',
                      'Zadar': '€250.00',
                      'Plitvice': '€270.00',
                    }[this.props.izlet] }
                    </p>
                  </div>


               <hr  className="line"/>
              </ToggleButton> 

              <ToggleButton variant="dark" name="odabirAuta" value="Peugeot Traveller">
                <Image className="kontaktIzletiCar" src="/slike/slikeAuta/standardVan.png" fluid />
                <p>Peugeot Traveller</p>
                
                  { icons_5 }

                  <div className="price">
                  <p>{ t('kontaktizleti.kontaktizleti-price') }
                    { {
                      'Međugorje': '€350.00',
                      'Biokovo': '€260.00',
                      'Klis': '€170.00',
                      'Mostar': '€350.00',
                      'Krka': '€200.00',
                      'Dubrovnik': '€300.00',
                      'Zadar': '€290.00',
                      'Plitvice': '€300.00',
                    }[this.props.izlet] }
                    </p>
                  </div>

                <hr  className="line"/>
              </ToggleButton>
              <ToggleButton variant="dark" name="odabirAuta" value="Mercedes Vito">
                <Image className="kontaktIzletiCar" src="/slike/slikeAuta/luxVan.png" fluid />
                <p>Mercedes Vito</p>

                  { icons_6 }

                  <div className="price">
                  <p>{ t('kontaktizleti.kontaktizleti-price') }
                    { {
                      'Međugorje': '€400.00',
                      'Biokovo': '€300.00',
                      'Klis': '€200.00',
                      'Mostar': '€400.00',
                      'Krka': '€220.00',
                      'Dubrovnik': '€350.00',
                      'Zadar': '€330.00',
                      'Plitvice': '€350.00',
                    }[this.props.izlet] }
                    </p>
                  </div>

                <hr  className="line"/>
              </ToggleButton>
              <ToggleButton variant="dark" name="odabirAuta" value="Mercedes E class">
                <Image className="kontaktIzletiCar" src="/slike/slikeAuta/eklasa.png" fluid />
                <p>Mercedes E class</p>

                  { icons_6 }

                  <div className="price">
                  <p>{ t('kontaktizleti.kontaktizleti-price') }
                    { {
                      'Međugorje': '€400.00',
                      'Biokovo': '€300.00',
                      'Klis': '€200.00',
                      'Mostar': '€400.00',
                      'Krka': '€220.00',
                      'Dubrovnik': '€350.00',
                      'Zadar': '€330.00',
                      'Plitvice': '€350.00',
                    }[this.props.izlet] }
                    </p>
                  </div>

                <hr  className="line"/>
              </ToggleButton>
              <ToggleButton variant="dark" name="odabirAuta" value="Renault Master">
                <Image className="kontaktIzletiCar" src="/slike/slikeAuta/mster.png" fluid />
                <p>Renault Master</p>

                  { icons_5 }
 
                  <div className="price">
                  <p>{ t('kontaktizleti.kontaktizleti-price') }
                    { {
                      'Međugorje': '€600.00',
                      'Biokovo': '€420.00',
                      'Klis': '€300.00',
                      'Mostar': '€600.00',
                      'Krka': '€350.00',
                      'Dubrovnik': '€500.00',
                      'Zadar': '€500.00',
                      'Plitvice': '€550.00',
                    }[this.props.izlet] }
                    </p>
                  </div>
               
                <hr  className="line"/>
              </ToggleButton>
            </ToggleButtonGroup><br />

            <label>
               <Form.Label> { t('kontaktizleti.kontaktizleti-date') } </Form.Label>
                <DatePicker 
                  name="date"
                  className="form-control"
                  selected={ this.state.value }
                  onChange={ (date) => this.setState({value:date}) }
                  showTimeSelect
                  timeIntervals={15}
                  timeFormat="H:mm"
                  dateFormat="dd.MM.yyyy H:mm z"
                  minDate={ this.minStartDate }
                  customInput={ <DateTimeButton /> } 
                  /* locale="hr-HR" */
                />
            </label>

            <Form.Group>
              <Form.Label>{ t('kontaktizleti.kontaktizleti-pay') }</Form.Label>
              <Form.Control as="select" defaultValue={this.props.izlet} size="md" custom name="paymethod">
                <option value="Cash">{ t('kontaktizleti.kontaktizleti-paycash') }</option>
                <option value="Card">{ t('kontaktizleti.kontaktizleti-paycard') }</option>
              </Form.Control>
              </Form.Group>

              <input type="hidden" name="excursion" value={ this.props.izlet } />
{/*
              <Form.Group>
              <Form.Label>{ t('kontaktizleti.kontaktizleti-excursion') }</Form.Label>
              <Form.Control as="select" defaultValue={this.props.izlet} size="md" custom name="excursion" disabled={true}>
                <option value="Zadar">Zadar</option>
                <option value="Plitvice">Plitvice</option>
                <option value="Biokovo">Biokovo</option>
                <option value="Dubrovnik">Dubrovnik</option>
                <option value="Klis">Klis</option>
                <option value="Krka">Krka</option>
                <option value="Međugorje">Međugorje</option>
                <option value="Mostar">Mostar</option>
              </Form.Control>
              </Form.Group>
*/}
            <Button variant="primary" type="submit">
              { t('kontaktizleti.kontaktizleti-btn') }
            </Button>
          </Form>
        </div>
    );
  }
};
const Kontaktizleti = withTranslation()(OrigKontaktizleti);

export default Kontaktizleti;
