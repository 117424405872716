import React from 'react';
import Figure from 'react-bootstrap/Figure'
import { HashLink as Link } from 'react-router-hash-link';

import { withTranslation } from 'react-i18next';

class OrigIzleti extends React.Component {
  render() {

    const { t } = this.props;

    return (
      
          <div>

            <div className="container"> 
            <div>
              <img className="logo" src="slike/logo.png" alt="logo"/>
          </div>
              <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="First slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main-2.jpg" alt="Second slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main-3.jpg" alt="Third slide"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
              </div>
              <div className="text">
              <div> <h2 className="h2izlet">{ t('izleti.title-izleti') }</h2></div>
              
                <div className="figuresdiv">
                  <Figure> 
                    <Link className ="linkilzet" to="/Zadar" >
                     <Figure.Image 
                      width={171}
                      height={180}
                      alt="171x180"
                      src="slike/izleti/zadar.jpeg" 
                      /> 
                    </Link>
                    <Figure.Caption>
                     <Link className ="linkilzet" to="/Zadar" > { t('zadar.zadar-title') } </Link>
                    </Figure.Caption>
                  </Figure>
                
                  <Figure>
                  <Link className ="linkilzet" to="/Plitvice" >
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src="slike/izleti/plitvice.jpeg"
                    />
                    </Link>
                    <Figure.Caption>
                     <Link className ="linkilzet" to="/Plitvice" > { t('plitvice.plitvice-title') } <br />  </Link>
                    </Figure.Caption>
                  </Figure>                
                         
                  <Figure>
                   <Link className ="linkilzet" to="/Biokovo" >
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src="slike/izleti/biokovo.jpg"
                    />
                    </Link>
                    <Figure.Caption>
                     <Link className ="linkilzet" to="/Biokovo" > { t('biokovo.biokovo-title') } </Link>
                    </Figure.Caption>
                  </Figure>                
                
                  <Figure>
                  <Link className ="linkilzet" to="/Dubrovnik" >
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src="slike/izleti/dubrovnik.jpeg"
                    />
                    </Link>
                    <Figure.Caption>
                     <Link className ="linkilzet" to="/Dubrovnik" > { t('dubrovnik.dubrovnik-title') } </Link>
                    </Figure.Caption>
                  </Figure>                
                
                  <Figure>
                  <Link className ="linkilzet" to="/Klis" >
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src="slike/izleti/klis.jpeg"
                    />
                    </Link>
                    <Figure.Caption>
                     <Link className ="linkilzet" to="/Klis" > { t('klis.klis-title') } </Link>
                    </Figure.Caption>
                  </Figure>                
                            
                  <Figure>
                  <Link className ="linkilzet" to="/Krka" >
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src="slike/izleti/sibenik.jpeg"
                    />
                    </Link>
                    <Figure.Caption>
                     <Link className ="linkilzet" to="/Krka" > { t('krka.krka-title') } </Link>
                    </Figure.Caption>
                  </Figure>                
                           
                  <Figure>
                  <Link className ="linkilzet" to="/Medugorje" >
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src="slike/izleti/medugorje.jpeg"
                    />
                    </Link>
                    <Figure.Caption>
                     <Link className ="linkilzet" to="/Medugorje" > { t('medugorje.medugorje-title') } </Link>
                    </Figure.Caption>
                  </Figure>                
                           
                  <Figure>
                  <Link className ="linkilzet" to="/Mostar" >
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src="slike/izleti/mostar.jpeg"
                    />
                    </Link>
                    <Figure.Caption>
                     <Link className ="linkilzet" to="/Mostar" > { t('mostar.mostar-title') } </Link>
                    </Figure.Caption>
                  </Figure>      
                </div>          
              </div>
            </div>
          </div>

     );
  }
}
const Izleti = withTranslation()(OrigIzleti);


export default Izleti;
