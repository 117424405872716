import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'

import { useTranslation } from 'react-i18next';

async function phpRequest(data) {
  const response = await fetch("/partner.php",
    {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data
    }
  );
  return response.json();
}


function Partner () {

  const { t } = useTranslation();

  function HandleSubmit(e) {
    /**********/
    /* SUBMIT */
    /**********/

    e.preventDefault();

    phpRequest(
      'email=' + encodeURIComponent(e.target.email.value) +
      '&imePrezime=' + encodeURIComponent(e.target.imePrezime.value) +
      '&mobitel=' + encodeURIComponent(e.target.mobitel.value) +
      '&uloga=' + encodeURIComponent(e.target.uloga.value) +
      '&podrucje=' + encodeURIComponent(e.target.podrucje.value) +
      '&napomena=' + encodeURIComponent(e.target.napomena.value)
    )
    .then(data => {
      if (data['status']==='OK') {
        // Inform user mail was sent
        alert(t('kontakt.kontakt-success-message'));
      } else {
        // Inform user about server error
        alert(data['message']);
      }
    })
    .catch(err => {
      console.error(err);
    });
  }

  return (
        <div>
          <div className="container">

            <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img className="d-block w-100" src="slike/main.jpeg" alt="First slide"/>
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src="slike/main.jpeg" alt="Second slide"/>
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src="slike/main.jpeg" alt="Third slide"/>
                </div>
              </div>
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
              </a>
            </div>

            <div className="text">
              <h4>{ t('partner.partner-title') }</h4>
              { t('partner.partner-title-text') }
              <p> <br />
              </p>

              <div className="formaizleti">
                <h3 className="reservetitle">{ t('partner.partner-form-title') }</h3>

                <Form name="contactform" onSubmit={HandleSubmit}>
                 
                  <Form.Group>
                    <Form.Label>{ t('partner.partner-form-1') }</Form.Label>
                    <Form.Control type="text" name="email" className="form-control" placeholder={ t('partner.partner-input-1') } />
                    <Form.Text className="text-muted">
                    </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{ t('partner.partner-form-2') }</Form.Label>
                    <Form.Control type="text" name="imePrezime" className="form-control" placeholder={ t('partner.partner-input-2') } />
                    <Form.Text className="text-muted">
                    </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{ t('partner.partner-form-3') }</Form.Label>
                    <Form.Control type="text" name="mobitel" className="form-control" placeholder={ t('partner.partner-input-3') } />
                    <Form.Text className="text-muted">
                    </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{ t('partner.partner-form-4') }</Form.Label>
                    <Form.Control as="select" name="uloga" className="form-control" >
                      <option>{ t('partnerOptions.option1') }</option>
                      <option>{ t('partnerOptions.option2') }</option>
                      <option>{ t('partnerOptions.option3') }</option>
                      <option>{ t('partnerOptions.option4') }</option>
                      <option>{ t('partnerOptions.option5') }</option>
                    </Form.Control>
                  </Form.Group>

                   <Form.Group>
                     <Form.Label>{ t('partner.partner-form-5') }</Form.Label>
                     <Form.Control as="textarea" type="text" name="podrucje" className="form-control" placeholder={ t('partner.partner-input-5') } rows={3} />
                   </Form.Group>

                   <Form.Group>
                     <Form.Label>{ t('partner.partner-form-6') }</Form.Label>
                     <Form.Control as="textarea" type="text" name="napomena" className="form-control" placeholder={ t('partner.partner-input-6') } rows={3} />
                   </Form.Group>

                  <Button variant="primary" type="submit">
                    { t('partner.partner-btn') }
                  </Button>
                </Form>
              </div>

            </div>
          </div>
        </div>
  );
}


export default Partner;
