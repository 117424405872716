import React from 'react';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import Kontaktizleti  from './kontaktizleti.js';

import { useTranslation } from 'react-i18next';

const Plitvice = (props) => {

const { t } = useTranslation();

  return (
  	<div>
      
            <div className="container"> 
            <div>
              <img className="logo" src="slike/logo.png" alt="logo"/>
          </div>
              <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="slike/izleti/izleti/plitvice1.jpg" alt="First slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/izleti/izleti/plitvice2.jpg" alt="Second slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/izleti/izleti/plitvice3.jpg" alt="Third slide"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
              </div>
              <div className="text">
                <h2 className="h2izlet">{ t('plitvice.plitvice-title') }</h2>
                <div>
                  <Button id="opcenito" className="morebtn" >{ t('plitvice.plitvice-Overview-title') }</Button>
                    <UncontrolledCollapse toggler="#opcenito">
                      <Card>
                        <CardBody>
                          <p>{ t('plitvice.plitvice-Overview-text') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>
                <hr className="line"/>
                <div>
                  <Button id="ukljuceno" className="morebtn">{ t('plitvice.plitvice-title-2') }</Button>
                    <UncontrolledCollapse toggler="#ukljuceno">
                      <Card>
                        <CardBody>
                <ul>
                  <li>{ t('plitvice.plitvice-list-1') }</li>
                  <li>{ t('plitvice.plitvice-list-2') }</li>
                  <li>{ t('plitvice.plitvice-list-3') }</li>
                  <li>{ t('plitvice.plitvice-list-4') }</li>
                  <li>{ t('plitvice.plitvice-list-5') }</li>
                  <li>{ t('plitvice.plitvice-list-6') }</li>
                  <li>{ t('plitvice.plitvice-list-7') }</li>
                  <li>{ t('plitvice.plitvice-list-8') }</li>
                </ul>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>
                <div>
                  <Button id="odlazak" className="morebtn">{ t('plitvice.plitvice-title-3') }</Button>
                    <UncontrolledCollapse toggler="#odlazak">
                      <Card>
                        <CardBody>
                          <p>{ t('plitvice.plitvice-text-2') }</p>
                          </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <div>
                  <Button id="ulaznica" className="morebtn">{ t('plitvice.plitvice-title-4') }</Button>
                    <UncontrolledCollapse toggler="#ulaznica">
                      <Card>
                        <CardBody>
                          <p>
                          { t('plitvice.plitvice-price-list-1') }
                          </p>
                          <ul>
                            <li>{ t('plitvice.plitvice-price-list-2') }</li>
                            <li>{ t('plitvice.plitvice-price-list-3') }</li>
                            <li>{ t('plitvice.plitvice-price-list-4') }</li>
                          </ul>
                          
                        </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <div>
                  <Button id="itinerar" className="morebtn">{ t('plitvice.plitvice-title-5') }</Button>
                    <UncontrolledCollapse toggler="#itinerar">
                      <Card>
                        <CardBody>
                          <p>{ t('plitvice.plitvice-itinerar-txt-1') }</p>
                          <p>{ t('plitvice.plitvice-itinerar-txt-2') }</p>
                          <p>{ t('plitvice.plitvice-itinerar-txt-3') }</p>
                          <p>{ t('plitvice.plitvice-itinerar-txt-4') }</p>
                          <p>{ t('plitvice.plitvice-itinerar-txt-5') }</p>
                          <p>{ t('plitvice.plitvice-itinerar-txt-6') }</p>
                        </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <div>
                  <Button id="info" className="morebtn">{ t('plitvice.plitvice-title-6') }</Button>
                    <UncontrolledCollapse toggler="#info">
                      <Card>
                        <CardBody>
                           
                          <ul>
                            <li>{ t('plitvice.plitvice-info-txt-1') }</li>
                            <li>{ t('plitvice.plitvice-info-txt-2') }</li>
                            <li>{ t('plitvice.plitvice-info-txt-3') }</li>
                            <li>{ t('plitvice.plitvice-info-txt-4') }</li>
                            <li>{ t('plitvice.plitvice-info-txt-5') }</li>
                            <li>{ t('plitvice.plitvice-info-txt-6') }</li>
                            <li>{ t('plitvice.plitvice-info-txt-7') }</li>
                          </ul>
                          
                        </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>
                <div>
                  <Button id="otkazivanje" className="morebtn">{ t('plitvice.plitvice-title-7') }</Button>
                    <UncontrolledCollapse toggler="#otkazivanje">
                      <Card>
                        <CardBody>
                           <p>
                          <li>{ t('plitvice.plitvice-cancel-txt') }</li>
                          </p>
                        </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <Kontaktizleti izlet="Plitvice"/>

              </div>
          </div>
    </div>
  );
}

export default Plitvice;

