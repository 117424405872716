import './Rezervacija.css';
import { 
  LoadScript, 
  Autocomplete, 
  DistanceMatrixService, 
  GoogleMap, 
  Marker,
  DirectionsService,
  DirectionsRenderer
  } from '@react-google-maps/api';
import { forwardRef, Component } from 'react';
import DatePicker from "react-datepicker";
import Image from 'react-bootstrap/Image';

import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import { withTranslation, useTranslation } from 'react-i18next';

import Tooltip from 'rc-tooltip';

const containerStyle = { width:'100%', height:'400px' };
const center = { lat: 43.5178739, lng: 16.4673943 };
const institut = { lat: 43.508117, lng: 16.388446 };  // 43.508117,16.388446
const mravince = { lat: 43.5351221, lng: 16.514821 };  // 43.5351221,16.514821

const OnApiLoaded = (map, comp) => {
  comp.map = map;
  var bounds = new window.google.maps.LatLngBounds();
  bounds.extend(institut);
  bounds.extend(mravince);
  map.fitBounds(bounds);
};


class OrigRouteMap extends Component {
  constructor(props, google) {
    super(props);
    this.distanceCallback = this.distanceCallback.bind(this);
  }

  distanceCallback(response) {
    const stateChange = {
      rutaPolaziste: '',
      rutaOdrediste: '',
      ruta: null
    };
    if( response && response.rows[0] ) {
      stateChange.rutaPolaziste = response.originAddresses[0];
      stateChange.rutaOdrediste = response.destinationAddresses[0];
      stateChange.ruta = response.rows[0].elements[0];
    }
    if(this.props.onUpdateRezervacija) {
       this.props.onUpdateRezervacija(stateChange);
    }

    if (this.props.polaziste && this.props.odrediste) {

      const DirectionsService = new window.google.maps.DirectionsService();

      DirectionsService.route({
        origin: this.props.polaziste,
        destination: this.props.odrediste,
        travelMode: window.google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK && this.props.onUpdateRezervacija) {
          this.props.onUpdateRezervacija({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  }

  render() {
    var DistanceMatrix = '';
    if (this.props.onUpdateRezervacija) {
      DistanceMatrix =
        <DistanceMatrixService
          options={{
                    origins: [
                      this.props.polaziste ?
                      this.props.polaziste :
                      center
                    ],
                    destinations: 
                      this.props.odrediste ?
                      [this.props.odrediste] :
                      []
                    ,
                    travelMode: "DRIVING",
                  }}
          callback={ this.distanceCallback }
        />
    }

    if(this.map) {
      var bounds = new window.google.maps.LatLngBounds();
      if (this.props.polaziste && this.props.odrediste) {
        /* handled by directions renderer
        bounds.extend(this.props.polaziste);
        bounds.extend(this.props.odrediste);
        this.map.fitBounds(bounds);
        */
      } else {
        this.map.setZoom(12);
        if (this.props.polaziste) {
          this.map.setCenter(this.props.polaziste);
        } else if (this.props.odrediste) {
          this.map.setCenter(this.props.odrediste);
        } else {
          bounds.extend(institut);
          bounds.extend(mravince);
          this.map.fitBounds(bounds);
        }
      }
    }

    const { t } = this.props;

    return (
        <GoogleMap
          mapContainerStyle={containerStyle}
          defaultCenter={center}
          defaultZoom={12}
          onLoad={(map) => OnApiLoaded(map, this) }
        >
          {DistanceMatrix}
          {this.props.polaziste && <Marker position={this.props.polaziste} icon="" label={ t('reservation.marker-start') } />}
          {this.props.odrediste && <Marker position={this.props.odrediste} icon="" label={ t('reservation.marker-destination') } />}
          {this.props.directions && <DirectionsRenderer
            directions={this.props.directions}
            options={{
              suppressMarkers:true,
              // suppressInfoWindows:true,
              // preserveViewport:true
            }}
            />
          }
        </GoogleMap>
    );
  }
}
const RouteMap = withTranslation()(OrigRouteMap);

/*
https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete
*/
const defaultBounds = {
  north: center.lat + 0.1,
  south: center.lat - 0.1,
  east: center.lng + 0.1,
  west: center.lng - 0.1,
};
const autocompleteOptions = {
  bounds: defaultBounds,
  componentRestrictions: { country: "hr" },
  origin: center,
  strictBounds: false,
  fields: [
    "address_components",
    "geometry"
  ]
};

class OrigPolaziste extends Component {
  constructor (props) {
    super(props);

    this.autocomplete = null;
    this.onLoad         = this.onLoad.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
  }

  onLoad (autocomplete) {
    this.autocomplete = autocomplete;
  }

  onPlaceChanged () {
    const field = document.getElementById("polaziste-input");
    const place = this.autocomplete.getPlace();
    if (this.autocomplete !== null) {
      const stateChange = {
        polaziste: field.value,
        polazisteLocation: null,
        polazisteData: null
      };
      if (place) {
        stateChange.polazisteData = place;
      }
      if (place.geometry) {
        stateChange.polazisteLocation = { 
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
      }
      this.props.onUpdateRezervacija(stateChange);
    } else {
      console.warn('Autocomplete not loaded yet');
    }
  }

  render () {

    const { t } = this.props;

    return (
      <label className="form-label">
        { t('reservation.start-label') }
        <Autocomplete
          onLoad={ this.onLoad }
          onPlaceChanged={ this.onPlaceChanged }
          options={ autocompleteOptions }
          defaultValue={ this.props.value }
        >
          <input
            id="polaziste-input"
            className="form-control"
            type="text"
            placeholder={ t('reservation.start-placeholder') }
            defaultValue={ this.props.value }
            style={{
              textOverflow: `ellipses`
            }}
          />
        </Autocomplete>
      </label>
    );
  }
}
const Polaziste = withTranslation()(OrigPolaziste);



class OrigOdrediste extends Component {
  constructor (props) {
    super(props);

    this.autocomplete = null;
    this.onLoad = this.onLoad.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
  }

  onLoad (autocomplete) {
    this.autocomplete = autocomplete;
  }

  onPlaceChanged () {
    const field = document.getElementById("odrediste-input");
    const place = this.autocomplete.getPlace();
    if (this.autocomplete !== null) {
      const stateChange = {
        odrediste: field.value,
        odredisteLocation: null,
        odredisteData: null
      };
      if (place) {
        stateChange.odredisteData = place;
      }
      if (place.geometry) {
        stateChange.odredisteLocation = { 
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
      }
      this.props.onUpdateRezervacija(stateChange);
    } else {
      console.warn('Autocomplete not loaded yet');
    }
  }

  render () {

    const { t } = this.props;

    return (
      <label className="form-label">
        { t('reservation.destination-label') }
        <Autocomplete
          onLoad={this.onLoad}
          onPlaceChanged={this.onPlaceChanged}
          options={autocompleteOptions}
          defaultValue={ this.props.value }
        >
          <input
            id="odrediste-input"
            className="form-control"
            type="text"
            placeholder={ t('reservation.destination-placeholder') }
            defaultValue={ this.props.value }
            style={{
              textOverflow: `ellipses`
            }}
          />
        </Autocomplete>
      </label>
    );
  }
}
const Odrediste = withTranslation()(OrigOdrediste);


function Datum(props) {
  /*
    https://reactdatepicker.com/
  */
  
  const { t } = useTranslation();
      
  const DateTimeButton = forwardRef(
    ({ value, onClick }, ref) => (
      <button className="btn btn-primary" onClick={ (e) => {
        e.preventDefault();
        onClick(e);
      }} ref={ref}>
        {value}
      </button>
    ),
  );

  return (
    <label className="form-label">
      { t('reservation.date-label') }<br/>
      
      <DatePicker
        className="form-control"
        selected={ props.value }
        onChange={ (date) => props.onUpdateRezervacija({ datumVrijeme: date }) }
        showTimeSelect
        timeIntervals={15}
        timeFormat={ t('reservation.date-timeformat') }
        dateFormat={ t('reservation.date-dateformat') }
        minDate={ props.minStartDate }
        closeOnScroll={true}
        customInput={ <DateTimeButton /> } 
        /* locale="hr-HR" */
      />
    </label>
  );
}


class OrigBrojPutnika extends Component {
  render() {

    const { t } = this.props;

    return (
      <label className="form-label">
        { t('reservation.numpassengers-label') }<br/>
        <div className="btn-group">
          <button 
            className="btn btn-primary"
            disabled={(this.props.value <= 1)}
            onClick={ (e) => {
              e.preventDefault();
              this.props.onUpdateRezervacija({ brojPutnika: this.props.value - 1 });
            }}
          >-</button>
          <div
          className="input text-center align-middle"
            style={{ width: '3em', paddingTop: '6px', background:'white', color:'black' }}
          /*  value={ this.props.value } */
          >
          { this.props.value }
            {/*
            onChange={ (e) => this.props.onUpdateRezervacija({
              brojPutnika: Math.max(1, Math.min(16, parseInt(e.target.value) || 1 ))
            }) }
            */}
          </div>
          <button
            className="btn btn-primary"
            disabled={(this.props.value >= 16)}
            onClick={ (e) => {
              e.preventDefault();
              this.props.onUpdateRezervacija({ brojPutnika: this.props.value + 1 }); 
            }}
          >+</button>
        </div>
      </label>
    );
  }
}
const BrojPutnika = withTranslation()(OrigBrojPutnika);


const izracunCijene = (ruta, vozilo, info) => {
  /*****************************/
  /* FORMULA za izracun cijene */
  /*****************************/

  var cijena = 0;
  var udaljenost = ruta.distance.value / 1000.0;
  // var conf = this.konfiguracija.vozila[vozilo];

  if (udaljenost < info.minKilometara) {
    cijena = 0.0;
  }
  else if (udaljenost <= info.kratkaKilometara) {
    cijena = udaljenost * info.kratkaCijenaPoKilometru;
  }
  else {
    cijena = udaljenost * info.dugaCijenaPoKilometru;
  }

  return cijena;
}


function VoziloInfo(props) {
  
  const { t } = useTranslation();
  const features_icons = {
    'O': 'suit',
    'W': 'wifi',
    'V': 'water',
    'A': 'plane',
    'S': 'seats',
    'X': 'cancel',
  };

  const features_tooltips = {
    'O': t('tooltips.tooltip-suit'),
    'W': t('tooltips.tooltip-wifi'),
    'V': t('tooltips.tooltip-water'),
    'A': t('tooltips.tooltip-plane'),
    'S': t('tooltips.tooltip-seats'),
    'X': t('tooltips.tooltip-cancel'),
  };

  const cijena = (props.ruta) ? izracunCijene(props.ruta, props.value, props.info) : 0;


  return (
    <div className="flex-column" style={{ height: '100%' }}>
      <div>{props.value}</div>
      <img className="img-fluid margin-bottom-auto" alt="" src={props.info.imgURL} />
      { cijena > 0 &&
        <div className="align-self-stretch">{ Math.round(cijena * 100) / 100 } Kn (&euro;{ Math.round(cijena * 100 / 7.5) / 100 })</div>
      }
      <div className="align-self-end">{
        props.info.oprema.split('').map( (i) => {
          return (
            <Tooltip 
              placement="bottom"
              trigger={['hover', 'click']}
              overlay={ <span>{ features_tooltips[i] }</span> }
              >
              <Image className="caricon-sm" src={ "/slike/icons/" + features_icons[i] + ".png" } fluid />
            </Tooltip>
            );
        })
      }</div>
    </div>
  );
}



function Vozilo(props) {
  
  const { t } = useTranslation();

  return (
    <label className="form-label" id="vehicle-reservation">
      { t('reservation.vehicle-label') }

      <ToggleButtonGroup name="vehicle" aria-label="Vehicle selection" className="mb-2 flex-wrap"
        onChange={ (v) => { props.onUpdateRezervacija({ vozilo: v}) }}
        value={ props.value }
      >
        {Object.keys(props.vozila).map((v) => {
          return (
            <ToggleButton type="radio" name="vehicle" variant='secondary' style={{ width:'203px', height:'203px' }}
              value={ v }
              disabled={ props.vozila[v].maxBrojPutnika < props.brojPutnika }
              checked={ props.value==v }>
              <VoziloInfo 
                value={ v }
                info={ props.vozila[v] }
                ruta={ props.ruta }
              />
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>      
    </label>
  );
}



function ImePrezime(props) {
  
  const { t } = useTranslation();

  return (
    <label className="form-label">
      { t('reservation.name-label') }
      <input className="form-control" type="text" placeholder={ t('reservation.name-placeholder') }
        value={ props.value }
        onChange={ (e) => props.onUpdateRezervacija({ imePrezime: e.target.value }) }
      />
    </label>
  );
}

function Email(props) {
  
  const { t } = useTranslation();

  return (
    <label className="form-label">
      { t('reservation.email-label') }
      <input className="form-control" type="email" placeholder={ t('reservation.email-placeholder') }
        value={ props.value }
        onChange={ (e) => props.onUpdateRezervacija({ email: e.target.value }) }
      />
    </label>
  );
}

function BrojTelefona(props) {
  
  const { t } = useTranslation();

  return (
    <label className="form-label">
      { t('reservation.tel-label') }
      <input className="form-control" type="text" placeholder={ t('reservation.tel-placeholder') }
        value={ props.value }
        onChange={ (e) => props.onUpdateRezervacija({ brojTelefona: e.target.value }) }
      />
    </label>
  );
}

function BrojLeta(props) {
   
  const { t } = useTranslation();

 return (
    <label className="form-label">
      { t('reservation.flight-label') }
      <input className="form-control" type="text" placeholder={ t('reservation.flight-placeholder') }
        value={ props.value }
        onChange={ (e) => props.onUpdateRezervacija({ brojLeta: e.target.value }) }
      />
    </label>
  );
}

function DodatnaNapomena(props) {
  
  const { t } = useTranslation();

  return (
    <label className="form-label" >
      { t('reservation.note-label') }
      <textarea className="form-control" rows="3"
        placeholder={ t('reservation.note-placeholder') }
        value={ props.value }
        onChange={ (e) => props.onUpdateRezervacija({ dodatnaNapomena: e.target.value }) }
      />
    </label>
  );
}


function Sazetak(props) {
  
  const { t } = useTranslation();

  return (
    <div className="left">
      <div className="polaziste" >{ t('reservation.summary-start') }: { props.rutaPolaziste }</div>
      <div className="odrediste" >{ t('reservation.summary-destination') }: { props.rutaOdrediste }</div>
      { props.ruta &&
      <div className="kilometri">
        { t('reservation.summary-distance') }: { props.ruta.distance.text }
      </div>
      }
      { props.ruta &&
      <div className="vrijeme">
        { t('reservation.summary-duration') }: { props.ruta.duration.text }
      </div>
      }
      { props.vozilo &&
      <div className="vozilo">
        { t('reservation.summary-vehicle') }: { props.vozilo }
      </div>
      }
      {(props.ruta && (props.cijena > 0.0)) &&
        <div className="cijena">
          { t('reservation.summary-price') }: { Math.round(props.cijena * 100) / 100 } Kn (&euro;{ Math.round(props.cijena * 100 / 7.5) / 100 })
        </div>
      }
    </div>
  );
}

async function rezervirajPHPRequest(data) {
  const response = await fetch("/rezerviraj.php",
    {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data
    }
  );
  return response.json();
}

async function payDriverPHPRequest(data) {
  const response = await fetch("/placanje_vozacu.php",
    {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data
    }
  );
  return response.json();
}



/****************************************************************/

class OrigRezervacija extends Component {
  constructor(props) {
    super(props);

    let query = new URLSearchParams(this.props.params);
    this.params = new Map(query.entries());

    this.handleReservation = this.handleReservation.bind(this);
    this.handlePayDriver   = this.handlePayDriver.bind(this);
    this.updateRezervacija = this.updateRezervacija.bind(this);
    //this.izracunCijene = izracunCijene.bind(this);

    // Config
    const minimumStartDelay = 720;  // u minutama

    this.minStartDate = new Date();
    this.minStartDate.setSeconds(0);
    this.minStartDate.setMinutes(15 * Math.ceil((
      this.minStartDate.getMinutes() + minimumStartDelay) / 15.0)
    );

    this.state = {
      faza: 0,
      polaziste: '',
      polazisteData: null,
      polazisteLocation: null,
      polazistePickUp: '',
      
      odrediste: '',
      odredisteData: null,
      odredisteLocation: null,
      odredistePickUp: '',
      
      brojPutnika: 1,
      datumVrijeme: this.minStartDate,
      vozilo: 'Peugeot 301',

      imePrezime: '',
      email: '',
      brojTelefona: '',
      brojLeta: '',
      dodatnaNapomena: '',
      
      ruta: null,
      rutaPolaziste: '',
      rutaOdrediste: '',
      cijena: 0,

      kratkaVoznjaKomentar: '',

      isReturnTrip: false,

      isAwaitingResponse: false,
      showErrorMessage: false,
      errorMessage: '',

      ShoppingCartID: '',
      TotalAmount: '',
      Signature: '',
    };
    this.defaultState = { ...this.state };

    if (this.params.has('id'))
    {
      let polazisteLocationArray = this.params.get('polazisteLocation').split('|');
      let odredisteLocationArray = this.params.get('odredisteLocation').split('|');
      let totalStr = this.params.get('cijena').toString();

      this.state['faza'] = 0;  // (this.params.get('cijena')===0) ? 4 : 3;
      this.state['polaziste'] = this.params.get('polaziste');
      this.state['polazisteLocation'] = {
        lat: parseFloat(polazisteLocationArray[0]),
        lng: parseFloat(polazisteLocationArray[1])
      };
      this.state['polazistePickUp'] = this.params.get('polazistePickUp');
      this.state['odrediste'] = this.params.get('odrediste');
      this.state['odredisteLocation'] = {
        lat: parseFloat(odredisteLocationArray[0]),
        lng: parseFloat(odredisteLocationArray[1])
      };
      this.state['odredistePickUp'] = this.params.get('odredistePickUp');
      
      this.state['brojPutnika'] = parseInt(this.params.get('brojPutnika'));
      this.state['datumVrijeme'] = new Date(this.params.get('datumVrijeme'));

      this.state['vozilo'] = this.params.get('vozilo');

      this.state['imePrezime'] = this.params.get('imePrezime');
      this.state['email'] = this.params.get('email');
      this.state['brojTelefona'] = this.params.get('brojTelefona');
      this.state['brojLeta'] = this.params.get('brojLeta');
      this.state['dodatnaNapomena'] = this.params.get('dodatnaNapomena');
      
      this.state['kratkaVoznjaKomentar'] = this.params.get('kratkaVoznjaKomentar');

      this.state['isReturnTrip'] = Boolean(parseInt(this.params.get('isReturnTrip')));

      this.state['ShoppingCartID'] = this.params.get('id');
      this.state['TotalAmount'] = totalStr.slice(0, -2) + ',' + totalStr.slice(-2);
      this.state['Signature'] = this.params.get('Signature');
    }


    this.konfiguracija = {
      vozila: {
        'Peugeot 301': {
          minKilometara: 10,
          kratkaKilometara: 30,
          kratkaCijenaPoKilometru: 10,
          dugaCijenaPoKilometru: 7.5,
          imgURL: '/slike/slikeAuta/standardCar.png',
          maxBrojPutnika: 3,
          oprema: 'OWVAX',
          // ...
        },
        'Peugeot Traveller': {
          minKilometara: 10,
          kratkaKilometara: 30,
          kratkaCijenaPoKilometru: 10,
          dugaCijenaPoKilometru: 7.5,
          imgURL: '/slike/slikeAuta/standardVan.png',
          maxBrojPutnika: 8,
          oprema: 'OWVAX',
          // ...
        },
        'Mercedes E class': {
          minKilometara: 10,
          kratkaKilometara: 30,
          kratkaCijenaPoKilometru: 11,
          dugaCijenaPoKilometru: 8,
          imgURL: '/slike/slikeAuta/eklasa.png',
          maxBrojPutnika: 3,
          oprema: 'OWVASX',
          // ...
        },
        'Mercedes Vito': {
          minKilometara: 10,
          kratkaKilometara: 30,
          kratkaCijenaPoKilometru: 11,
          dugaCijenaPoKilometru: 8,
          imgURL: '/slike/slikeAuta/luxVan.png',
          maxBrojPutnika: 8,
          oprema: 'OWVASX',
          // ...
        },
        'Renault Master': {
          minKilometara: 10,
          kratkaKilometara: 30,
          kratkaCijenaPoKilometru: 18,
          dugaCijenaPoKilometru: 16,
          imgURL: '/slike/slikeAuta/mster.png',
          maxBrojPutnika: 16,
          oprema: 'OWVAX',
          // ...
        },
      },
    };
  }


  componentDidUpdate() {
    if (this.state.Signature && this.state.faza===0)
    {
      const { t } = this.props;

      alert(t('reservation.form-result-' + this.params.get('result')) + this.params.get('id'));

      if (this.params.get('result')=='2')
      {
        this.setState({ faza: 4 });
      } else {
        this.setState({
          faza: (this.state.TotalAmount===0) ? 4 : 3
        });
      }
      
    }
  }

  updateRezervacija(stateChange) {
    /*****************************/
    /* UPDATE Info               */
    /*  + recalculation          */
    /*  + validation             */
    /*****************************/

    if (stateChange.hasOwnProperty('faza')) {

      window.scrollTo(0, {
        true: [500, 840, 440, 500, 500],
        false: [380, 700, 320, 380, 380]
      }[window.innerWidth>992][stateChange['faza']]);
    }

    if (stateChange.hasOwnProperty('ruta') ||
        stateChange.hasOwnProperty('vozilo') ) {
      var ruta = stateChange.ruta ? stateChange.ruta : this.state.ruta;
      var vozilo = stateChange.vozilo ? stateChange.vozilo : this.state.vozilo;

      if (ruta && vozilo && (
          !this.state.ruta ||
          this.state.ruta.distance.value !== ruta.distance.value ||
          this.state.ruta.duration.value !== ruta.duration.value ||
          this.state.vozilo !== vozilo)) {
        stateChange.cijena = izracunCijene(ruta, vozilo, this.konfiguracija.vozila[vozilo]);
        this.setState(state => stateChange);
      }
    }
    else {
      this.setState(state => stateChange);
    }
  }



  handleReservation(e) {
    /***************/
    /* REZERVACIJA */
    /***************/

    e.preventDefault();

    this.setState(state => { return { isAwaitingResponse: true }});

    rezervirajPHPRequest(
      'polaziste=' + encodeURIComponent(this.state.polaziste) +
      '&polazisteLocation=' + this.state.polazisteLocation.lat + '|' + this.state.polazisteLocation.lng +
      '&polazistePickUp=' + encodeURIComponent(this.state.polazistePickUp) +
      '&odrediste=' + encodeURIComponent(this.state.odrediste) +
      '&odredisteLocation=' + this.state.odredisteLocation.lat + '|' + this.state.odredisteLocation.lng +
      '&odredistePickUp=' + encodeURIComponent(this.state.odredistePickUp) +
      '&brojPutnika=' + this.state.brojPutnika +
      '&datumVrijeme=' + encodeURIComponent(this.state.datumVrijeme) +
      '&vozilo=' + encodeURIComponent(this.state.vozilo) +
      
      '&imePrezime=' + encodeURIComponent(this.state.imePrezime) +
      '&email=' + encodeURIComponent(this.state.email) +
      '&brojTelefona=' + encodeURIComponent(this.state.brojTelefona) +
      '&brojLeta=' + encodeURIComponent(this.state.brojLeta) +
      '&dodatnaNapomena=' + encodeURIComponent(this.state.dodatnaNapomena) +

      '&cijena=' + this.state.cijena +
      '&udaljenost=' + this.state.ruta.distance.text +
      '&trajanje=' + this.state.ruta.duration.text +
      '&kratkaVoznjaKomentar=' + encodeURIComponent(this.state.kratkaVoznjaKomentar) +
      '&isReturnTrip=' + Number(this.state.isReturnTrip)
       /*JSON.stringify({
        // email: 'bla@bla',
        ime_prezime: this.state.imePrezime,
        comments: 'haha'
      })*/

    )
    .then(data => {
      if (data['status']==='OK') {
        const { t } = this.props;

        alert(t('reservation.form-reservation-success'));

        // Add payment information to the state
        var totalStr = data['payData']['TotalAmount'].toString();
        this.updateRezervacija({
          ShoppingCartID: data['payData']['ShoppingCartID'],
          TotalAmount: totalStr.slice(0, -2) + ',' + totalStr.slice(-2),
          Signature: data['message'],
          faza: (data['payData']['TotalAmount']===0) ? 4 : 3  // zero-based -> Go to Faza 4 or 5
        });
      } else {
        // Inform user about server error
        alert(data['message']);
      }      
      this.setState(state => { return { isAwaitingResponse: false }});
    })
    .catch(err => {
      console.error(err);
      console.error("Error handling won't work with cross-origin");
      this.setState(state => { return { isAwaitingResponse: false }});
    });

  }




  handlePayDriver(e) {
    /***************/
    /* PAY DRIVER  */
    /***************/

    e.preventDefault();

    this.setState(state => { return { isAwaitingResponse: true }});

    payDriverPHPRequest(
      'id=' + encodeURIComponent(this.state.ShoppingCartID)
    )
    .then(data => {
      if (data['status']==='OK') {
        this.updateRezervacija({ faza: 4 });
      } else {
        // Inform user about server error
        alert(data['message']);
      }      
      this.setState(state => { return { isAwaitingResponse: false }});
    })
    .catch(err => {
      console.error(err);
      console.error("Error handling won't work with cross-origin");
      this.setState(state => { return { isAwaitingResponse: false }});
    });

  }

  render() {

    const { t } = this.props;


    /***************************************************/
    /* FAZA 1
    /***************************************************/
    const Faza_1 = (
      <div>
        <RouteMap
          polaziste={ this.state.polazisteLocation }
          odrediste={ this.state.odredisteLocation }
          onUpdateRezervacija={ this.updateRezervacija }
          directions={ this.state.directions }
        />
        <Polaziste
          disabled={ this.state.Signature }
          value={ this.state.polaziste }
          onUpdateRezervacija={ this.updateRezervacija }
        />
        <Odrediste
          disabled={ this.state.Signature }
          value={ this.state.odrediste }
          onUpdateRezervacija={ this.updateRezervacija }
        />
        <Datum
          disabled={ this.state.Signature }
          value={ this.state.datumVrijeme }
          minStartDate={ this.minStartDate }
          onUpdateRezervacija={ this.updateRezervacija }
        />
        <BrojPutnika
          disabled={ this.state.Signature }
          value={ this.state.brojPutnika }
          onUpdateRezervacija={ this.updateRezervacija }
        />
        <button
          className="btn btn-primary"
          disabled={!(
            this.state.polazisteLocation &&
            this.state.odredisteLocation
            )}
          onClick={ () => (this.updateRezervacija({ faza: 1 }))}
        >{ t('reservation.form-get-a-ride') }</button>
      </div>
      );

    /***************************************************/
    /* FAZA 2  
    /***************************************************/
    const Faza_2 = (
      <div>
        <RouteMap
          polaziste={ this.state.polazisteLocation }
          odrediste={ this.state.odredisteLocation }
          directions={ this.state.directions }
        />

        <Sazetak
          rutaPolaziste={ this.state.rutaPolaziste }
          rutaOdrediste={ this.state.rutaOdrediste }
          ruta={ this.state.ruta }
          /*cijena={ this.state.cijena }*/
        />

        <input
          disabled={ this.state.Signature }
          placeholder={ t('reservation.form-shortride') }
          style={ (this.state.cijena===0.0) ? {} : {display: 'none'} }
          type="text"
          value={ this.state.kratkaVoznjaKomentar }
          className="form-control"
          onChange={ (e) => this.updateRezervacija({ kratkaVoznjaKomentar: e.target.value }) }
        />
       
        <div className="right">
          <Datum
            disabled={ this.state.Signature }
            value={ this.state.datumVrijeme }
            minStartDate={ this.minStartDate }
            onUpdateRezervacija={ this.updateRezervacija }
          />
          <BrojPutnika 
            disabled={ this.state.Signature }
            value={ this.state.brojPutnika }
            onUpdateRezervacija={ this.updateRezervacija }
          />
          
          <Vozilo
            disabled={ this.state.Signature }
            value={ this.state.vozilo }
            ruta={ this.state.ruta }
            brojPutnika={ this.state.brojPutnika }
            vozila={ this.konfiguracija.vozila }
            onUpdateRezervacija={ this.updateRezervacija }
          />

        </div>


        <button
          className="btn btn-primary btnNext"
          disabled={!(
            this.state.polazisteLocation &&
            this.state.odredisteLocation &&
            this.state.vozilo &&
            this.state.brojPutnika <= this.konfiguracija.vozila[this.state.vozilo].maxBrojPutnika
            )}
          onClick={ () => (this.updateRezervacija({ faza: 2 }))}
        >{ t('reservation.form-next') }</button>
      </div>
      );

    /***************************************************/
    /* FAZA 3  
    /***************************************************/
    const Faza_3 = (
      <div>

        <Sazetak
          rutaPolaziste={ this.state.rutaPolaziste }
          rutaOdrediste={ this.state.rutaOdrediste }
          ruta={ this.state.ruta }
          vozilo={ this.state.vozilo }
          cijena={ this.state.cijena }
        />

        <ImePrezime
          disabled={ this.state.Signature }
          value={ this.state.imePrezime } 
          onUpdateRezervacija={ this.updateRezervacija }
        />
        <Email 
          disabled={ this.state.Signature }
          value={ this.state.email } 
          onUpdateRezervacija={ this.updateRezervacija }
        />
        <BrojTelefona
          disabled={ this.state.Signature }
          value={ this.state.brojTelefona } 
          onUpdateRezervacija={ this.updateRezervacija }
        />
        <BrojLeta
          disabled={ this.state.Signature }
          value={ this.state.brojLeta } 
          onUpdateRezervacija={ this.updateRezervacija }
        />
        <DodatnaNapomena
          disabled={ this.state.Signature }
          value={ this.state.dodatnaNapomena } 
          onUpdateRezervacija={ this.updateRezervacija }
        />

        <button className="btn btn-primary"
          disabled={!(
            this.state.ruta &&
            this.state.imePrezime &&
            this.state.email &&
            !this.state.isAwaitingResponse
            
            // TODO Vidi s Tahijem koja su obavezna polja

            // this.state.brojTelefona &&
            // (this.state.email || this.state.brojTelefona)
            
          )}
          onClick={ this.handleReservation }
          >{ t('reservation.form-rezerviraj') }
        </button>

      </div>
      );

    /***************************************************/
    /* FAZA 4  
    /***************************************************/
    const Faza_4 = (
      <div>

        <Sazetak
          rutaPolaziste={ this.state.rutaPolaziste }
          rutaOdrediste={ this.state.rutaOdrediste }
          ruta={ this.state.ruta }
          vozilo={ this.state.vozilo }
          cijena={ this.state.cijena }
        />

        <div>
          { t('reservation.form-reservation-phase4') }
        </div>

        <input type="hidden" name="ShopID" value="10002167" />
        <input type="hidden" name="Version" value="2.0" />
        <input type="hidden" name="ReturnURL" value="https://www.macitours.hr/payway_success.php" />
        <input type="hidden" name="ReturnErrorURL" value="https://www.macitours.hr/payway_fail.php" />
        <input type="hidden" name="CancelURL" value="https://www.macitours.hr/payway_cancel.php" />
        <input type="hidden" name="ReturnMethod" value="POST" />
        
        <input type="hidden" name="ShoppingCartID" value={ this.state.ShoppingCartID } />
        <input type="hidden" name="TotalAmount" value={ this.state.TotalAmount } />
        <input type="hidden" name="Signature" value={ this.state.Signature } />

        <button className="btn btn-primary btn-pay"
          onClick={ this.handlePayDriver }
        >{ t('reservation.form-pay-driver') }</button>

        <button className="btn btn-primary btn-pay" type="submit">
          { t('reservation.form-pay-payway') }
        </button>

      </div>
      );

    /***************************************************/
    /* FAZA 5
    /***************************************************/
    const Faza_5 = (
      <div>

        <div>
          { t('reservation.form-reservation-phase5-1') }
        </div>

        {!this.state.isReturnTrip && (
          <div>
          
            <div>
              { t('reservation.form-reservation-phase5-2') }
            </div>

            <button className="btn btn-primary"
              onClick={ () => (this.updateRezervacija({
                faza: 0,  // natrag na pocetak

                // Opcionalno: automatski postavi povratnu rutu
                polaziste: this.state.odrediste,
                polazisteData: this.state.odredisteData,
                polazisteLocation: this.state.odredisteLocation,
                polazistePickUp: this.state.odredistePickUp,
                odrediste: this.state.polaziste,
                odredisteData: this.state.polazisteData,
                odredisteLocation: this.state.polazisteLocation,
                odredistePickUp: this.state.polazistePickUp,

                isReturnTrip: true,

                showErrorMessage: false,
                errorMessage: '',

                ShoppingCartID: '',
                TotalAmount: '',
                Signature: '',
              }))}
            >{ t('reservation.form-return-yes') }</button>
          </div>
          )
        }

        <button className="btn btn-primary"
          onClick={ () => (this.setState(state => this.defaultState))}
        >{ t('reservation.form-return-no') }</button>

      </div>
      );



    /***************************************************/
    const faze = [Faza_1, Faza_2, Faza_3, Faza_4, Faza_5];
    return (
      <form
        className="ContactForm"
        method="post"
        action="https://form.payway.com.hr/authorization.aspx"
        onSubmit={ this.state.faza!==3 && (e => { e.preventDefault(); }) }
        >

        <LoadScript
          googleMapsApiKey="AIzaSyDv3XhunnqrTfLLXsx4COxbCzJyjm3eJ0U"
          libraries={["places"]}
        >
          { faze[this.state.faza] }
        </LoadScript>

      </form>
    );
  }
}

const Rezervacija = withTranslation()(OrigRezervacija);

export default Rezervacija;


