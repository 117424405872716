import React from 'react';
import ReactPlayer from "react-player"

import { useTranslation } from 'react-i18next';

const Covid = (props) => {
  const { t } = useTranslation();

  return (
  	<div>
          <div className="container"> 
        {/*     <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="First slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="Second slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="Third slide"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
              </div> */} 
              <div className="text">
                <h4>{ t('covid.title-covid') }</h4>

                <hr className="line"/>

                <p>{ t('covid.covid-text') }</p>

                <h4>{ t('covid.title-mesures') }</h4>

                <hr className="line"/>

                <p>{ t('covid.mesures-text') }</p>

                <div className='player-wrapper'>
                <ReactPlayer
                  url="https://youtu.be/6XdjmB4IY3M"
                   width='100%'   
                  />
                </div>

                <p>{ t('covid.mesures-text-2') }</p>
                <p>{ t('covid.mesures-text-3') }</p>

                <h4>{ t('covid.reservation-title') }</h4>

                <hr className="line"/>

                <p className="bold">{ t('covid.reservation-text') } </p>

                <h4>{ t('covid.cancelation-title') }</h4>

                <hr className="line"/>

                <p>{ t('covid.cancelation-text') }</p>

                <h4>{ t('covid.info-title') }</h4>

                <hr className="line"/>

                <p>{ t('covid.info-text') }</p>

                <ul className="lista">
                  <li className="linkmail">E-mail – <a href="mailto:info@macitours.hr">info@macitours.hr</a></li>
                  <li>{ t('covid.info-phone') } <a href="tel:+385 91 225 5539">+385 91 225 5539</a></li>
                </ul>
                
              </div>
          </div>
    </div>
  );
}

export default Covid;

