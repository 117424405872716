import React from 'react';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';

import { useTranslation } from 'react-i18next';

const Faq = (props) => {

  const { t } = useTranslation();

  return (
  	<div>
            <div className="container"> 
            <div>
              <img className="logo" src="slike/logo.png" alt="logo"/>
          </div>
             <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="First slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main-2.jpg" alt="Second slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main-3.jpg" alt="Third slide"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
              </div>
              <div className="text">
                <h2 className="h2izlet">{ t('faq.title-faq') }</h2>
                <div>
                  <h3>{ t('faq.title-general') }</h3>
                  <Button id="opc1" className="morebtn" >{ t('faq.quastion-1') }</Button>
                    <UncontrolledCollapse toggler="#opc1">
                      <Card>
                        <CardBody>
                          <p>{ t('faq.answer-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                  <div>
                  <Button id="opc2" className="morebtn" >{ t('faq.quastion-2') }</Button>
                    <UncontrolledCollapse toggler="#opc2">
                      <Card>
                        <CardBody>
                          <p>{ t('faq.answer-2') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                  <div>
                  <Button id="opc3" className="morebtn" >{ t('faq.quastion-3') }</Button>
                    <UncontrolledCollapse toggler="#opc3">
                      <Card>
                        <CardBody>
                          <p>{ t('faq.answer-3-1') }</p>
                          <ul>
                            <li>{ t('faq.answer-3-2') }</li>
                            <li>{ t('faq.answer-3-3') }</li>
                            <li>{ t('faq.answer-3-4') }</li>
                            <li>{ t('faq.answer-3-5') }</li>
                            <li>{ t('faq.answer-3-6') }</li>
                          </ul>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                  <div>
                  <Button id="opc4" className="morebtn" >{ t('faq.quastion-4') }</Button>
                    <UncontrolledCollapse toggler="#opc4">
                      <Card>
                        <CardBody>
                          <p>{ t('faq.answer-4') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                  <div>
                  <Button id="opc5" className="morebtn" >{ t('faq.quastion-5') }</Button>
                    <UncontrolledCollapse toggler="#opc5">
                      <Card>
                        <CardBody>
                          <p>{ t('faq.answer-5') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                  <div>
                  <Button id="opc6" className="morebtn" >{ t('faq.quastion-6') }</Button>
                    <UncontrolledCollapse toggler="#opc6">
                      <Card>
                        <CardBody>
                          <p>{ t('faq.answer-6') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                <hr className="line"/>
                
                <div>
                  <h3>{ t('faq.title-booking') }</h3>
                  <Button id="rez1" className="morebtn">{ t('faq.quastion-booking-1') }</Button>
                    <UncontrolledCollapse toggler="#rez1">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-booking-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="rez2" className="morebtn">{ t('faq.quastion-booking-2') }</Button>
                    <UncontrolledCollapse toggler="#rez2">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-booking-2') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="rez3" className="morebtn">{ t('faq.quastion-booking-3') }</Button>
                    <UncontrolledCollapse toggler="#rez3">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-booking-3') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <div>
                  <h3>{ t('faq.title-reservation') }</h3>
                  <Button id="pot1" className="morebtn">{ t('faq.quastion-reservation-1') }</Button>
                    <UncontrolledCollapse toggler="#pot1">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-reservation-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="pot2" className="morebtn">{ t('faq.quastion-reservation-2') }</Button>
                    <UncontrolledCollapse toggler="#pot2">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-reservation-2') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="pot3" className="morebtn">{ t('faq.quastion-reservation-3') }</Button>
                    <UncontrolledCollapse toggler="#pot3">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-reservation-3') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="pot4" className="morebtn">{ t('faq.quastion-reservation-4') }</Button>
                    <UncontrolledCollapse toggler="#pot4">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-reservation-4') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <div>
                  <h3>{ t('faq.title-more-info') }</h3>
                  <Button id="dod1" className="morebtn">{ t('faq.quastion-more-info-1') }</Button>
                    <UncontrolledCollapse toggler="#dod1">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-more-info-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                 <div>
                  <Button id="dod2" className="morebtn">{ t('faq.quastion-more-info-2') }</Button>
                    <UncontrolledCollapse toggler="#dod2">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-more-info-2') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="dod3" className="morebtn">{ t('faq.quastion-more-info-3') }</Button>
                    <UncontrolledCollapse toggler="#dod3">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-more-info-3') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="dod4" className="morebtn">{ t('faq.quastion-more-info-4') }</Button>
                    <UncontrolledCollapse toggler="#dod4">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-more-info-4') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                 <div>
                  <Button id="dod5" className="morebtn">{ t('faq.quastion-more-info-5') }</Button>
                    <UncontrolledCollapse toggler="#dod5">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-more-info-5') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                 <div>
                  <Button id="dod6" className="morebtn">{ t('faq.quastion-more-info-6') }</Button>
                    <UncontrolledCollapse toggler="#dod6">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-more-info-6') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                 <div>
                  <Button id="dod7" className="morebtn">{ t('faq.quastion-more-info-7') }</Button>
                    <UncontrolledCollapse toggler="#dod7">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-more-info-7') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                 <div>
                  <Button id="dod8" className="morebtn">{ t('faq.quastion-more-info-8') }</Button>
                    <UncontrolledCollapse toggler="#dod8">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-more-info-8') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                 <div>
                  <Button id="dod9" className="morebtn">{ t('faq.quastion-more-info-9') }</Button>
                    <UncontrolledCollapse toggler="#dod9">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-more-info-9') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <div>
                  <h3>{ t('faq.title-pay') }</h3>
                  <Button id="pla1" className="morebtn">{ t('faq.quastion-pay-1') }</Button>
                    <UncontrolledCollapse toggler="#pla1">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-pay-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="pla2" className="morebtn">{ t('faq.quastion-pay-2') }</Button>
                    <UncontrolledCollapse toggler="#pla2">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-pay-2') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="pla3" className="morebtn">{ t('faq.quastion-pay-3') }</Button>
                    <UncontrolledCollapse toggler="#pla3">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-pay-3') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="pla4" className="morebtn">{ t('faq.quastion-pay-4') }</Button>
                    <UncontrolledCollapse toggler="#pla4">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-pay-4') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="pla5" className="morebtn">{ t('faq.quastion-pay-5') }</Button>
                    <UncontrolledCollapse toggler="#pla5">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-pay-5') }</p>
                        <ul>
                          <li>{ t('faq.answer-pay-5-1') }</li>
                          <li>{ t('faq.answer-pay-5-2') } </li>
                          <li>{ t('faq.answer-pay-5-3') }</li>
                          <li>{ t('faq.answer-pay-5-4') }</li>
                        </ul>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <hr className="line"/>
                <div>
                  <h3>{ t('faq.title-privacy') }</h3>
                  <Button id="izj1" className="morebtn">{ t('faq.quastion-privacy-1') }</Button>
                    <UncontrolledCollapse toggler="#izj1">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-privacy-1') }</p>
                       
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="izj2" className="morebtn">{ t('faq.quastion-privacy-2') }</Button>
                    <UncontrolledCollapse toggler="#izj2">
                      <Card>
                        <CardBody>
                        <p>Maci-Tours, obrt za prijevoz i usluge  se obavezuje pružati zaštitu osobnim podacima kupaca, na način da prikuplja samo nužne, 
                        osnovne podatke o kupcima/korisnicima koji su nužni za ispunjenje naših obveza; informira kupce o načinu korištenja prikupljenih podataka, 
                        redovito daje kupcima mogućnost izbora o upotrebi njihovih podataka, uključujući mogućnost odluke žele li ili ne da se njihovo ime ukloni s 
                        lista koje se koriste za marketinške kampanje. Svi se podaci o korisnicima strogo čuvaju i dostupni su samo djelatnicima kojima su ti podaci 
                        nužni za obavljanje posla. Svi djelatnici Maci-Tours, obrt za prijevoz i usluge i poslovni partneri odgovorni su za poštivanje načela zaštite privatnosti.</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="izj3" className="morebtn">{ t('faq.quastion-privacy-3') }</Button>
                    <UncontrolledCollapse toggler="#izj3">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-privacy-3-1') }</p>
                        <p>{ t('faq.answer-privacy-3-2') }</p>
                        <p>{ t('faq.answer-privacy-3-3') }</p>
                        <p>{ t('faq.answer-privacy-3-4') }</p>
                        <p>{ t('faq.answer-privacy-3-5') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                 <div>
                  <Button id="izj4" className="morebtn">{ t('faq.quastion-privacy-4') }</Button>
                    <UncontrolledCollapse toggler="#izj4">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-privacy-4-1') }</p>
                        <p>{ t('faq.answer-privacy-4-2') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="izj5" className="morebtn">{ t('faq.quastion-privacy-5') }</Button>
                    <UncontrolledCollapse toggler="#izj5">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-privacy-5') }</p>
                        
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <div>
                  <Button id="izj6" className="morebtn">{ t('faq.quastion-privacy-6') }</Button>
                    <UncontrolledCollapse toggler="#izj6">
                      <Card>
                        <CardBody>
                        <p>{ t('faq.answer-privacy-6-1') }</p>
                        <p>{ t('faq.answer-privacy-6-2') }</p>
                        <ul>
                          <li>{ t('faq.answer-privacy-6-3') }</li>
                          <li><a className="linkmail" href="mailto:info@macitours.hr">Email: info@macitours.hr</a></li>
                          <li>{ t('faq.answer-privacy-6-4') }</li>
                        </ul>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <div className="allcards">
                    <a className="cards" target="_blank" rel="noreferrer" href="https://www.hrvatskitelekom.hr/poslovni/ict/payway"><img src="slike/cards/payway.png" alt="payway"/></a>
                    <a className="cards" target="_blank" rel="noreferrer" href="https://www.visa.com.hr/"><img src="slike/cards/visa.png" alt="visa"/></a>
                    <a className="cards" target="_blank" rel="noreferrer" href="https://www.mastercard.hr/hr-hr.html"><img src="slike/cards/mastercard.png" alt="mastercard"/></a>
                    <a className="cards" target="_blank" rel="noreferrer" href="http://www.maestrocard.com/gateway/index.html"><img src="slike/cards/maestro.png" alt="maestro"/></a>
                </div>

              </div>
          </div>
    </div>
  );
}

export default Faq;

