import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'

// Import translations
import './i18n';
import { useTranslation } from 'react-i18next';


async function phpRequest(data) {
  const response = await fetch("/send_form_email.php",
    {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data
    }
  );
  return response.json();
}


function Kontakt () {

  const { t } = useTranslation();

  function HandleSubmit(e) {
    /**********/
    /* SUBMIT */
    /**********/
    e.preventDefault();
    
    phpRequest(
      'email=' + encodeURIComponent(e.target.email.value) +
      '&imePrezime=' + encodeURIComponent(e.target.imePrezime.value) +
      '&upit=' + encodeURIComponent(e.target.upit.value)
    )
    .then(data => {
      if (data['status']==='OK') {
        // Inform user mail was sent
        alert(t('kontakt.kontakt-success-message'));
      } else {
        // Inform user about server error
        alert(data['message']);
      }
    })
    .catch(err => {
      console.error("Error handling won't work with cross-origin");
    });
  }

  return (
        <div>
          <div className="container">

            <div>
              <img className="logo" src="slike/logo.png" alt="logo"/>
          </div>
            <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img className="d-block w-100" src="slike/main.jpeg" alt="First slide"/>
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src="slike/main-2.jpg" alt="Second slide"/>
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src="slike/main-3.jpg" alt="Third slide"/>
                </div>
              </div>
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
              </a>
            </div>

            <div className="text">
              <h4>{ t('kontakt.kontakt-title') }</h4>
              <p>{ t('kontakt.kontakt-mail') } <a className="linkmail" href="mailto:info@macitours.hr">info@macitours.hr</a><br />
              { t('kontakt.kontakt-phone') } <br />
              { t('kontakt.kontakt-text') }</p>

              <div className="formaizleti">
                <h3 className="reservetitle">{ t('kontakt.kontakt-form-title') }</h3>

                <Form name="contactform" onSubmit={HandleSubmit}>
                 
                  <Form.Group>
                    <Form.Label>{ t('kontakt.kontakt-form-mail') }</Form.Label>
                    <Form.Control type="text" name="email" className="form-control" placeholder={ t('kontakt.kontakt-form-mail-placeholder') } />
                    <Form.Text className="text-muted">
                    </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{ t('kontakt.kontakt-form-name') }</Form.Label>
                    <Form.Control type="text" name="imePrezime" className="form-control" placeholder={ t('kontakt.kontakt-form-name-placeholder') } />
                    <Form.Text className="text-muted">
                    </Form.Text>
                  </Form.Group>

                   <Form.Group>
                     <Form.Label>{ t('kontakt.kontakt-form-question') }</Form.Label>
                     <Form.Control as="textarea" type="text" name="upit" className="form-control" placeholder={ t('kontakt.kontakt-form-question-placeholder') } rows={3} />
                   </Form.Group>

                  <Button variant="primary" type="submit">
                    { t('kontakt.kontakt-form-btn') }
                  </Button>
                </Form>
              </div>

            </div>
          </div>
        </div>
  );
}


export default Kontakt;
