import React from 'react';
import { useTranslation } from 'react-i18next';

const Informacije = (props) => {

  const { t } = useTranslation();

  return (
  	<div>
            <div className="container"> 
             <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="First slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="Second slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="Third slide"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
              </div>
              <div className="text">
                <h2 className="h2izlet">{ t('information.information-title') }</h2>
                  <ul className="infolist">
                    <li>{ t('information.information-list-1') }</li>
                    <li>{ t('information.information-list-2') }</li>
                    <li>{ t('information.information-list-3') }</li>
                    <li>{ t('information.information-list-4') }</li>
                    <li>{ t('information.information-list-5') }</li>
                    <li>{ t('information.information-list-6') }</li>
                    <li>{ t('information.information-list-7') }</li>
                    <li>{ t('information.information-list-8') }</li>
                    <li>{ t('information.information-list-9') }</li>
                    <li>{ t('information.information-list-10') }</li>
                    <li>{ t('information.information-list-11') }</li>
                  </ul>

              </div>
          </div>
    </div>
  );
}

export default Informacije;

