import React from 'react';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';

import { useTranslation } from 'react-i18next';

const Uvjeti = (props) => {

const { t } = useTranslation();

  return (
  	<div>
          <div className="container"> 
        {/*     <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="First slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="Second slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/main.jpeg" alt="Third slide"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
              </div> */} 
              <div className="text">
                <h2 className="h2izlet">{ t('uvjeti.uvjeti-title') }</h2>
                <div>
                  <Button id="opc1" className="morebtn" >{ t('uvjeti.uvjeti-title-1') }</Button>
                    <UncontrolledCollapse toggler="#opc1">
                      <Card>
                        <CardBody>
                          <p>{ t('uvjeti.uvjeti-text-1-1') }</p>
                          <p>{ t('uvjeti.uvjeti-text-1-2') }</p>
                          <p>{ t('uvjeti.uvjeti-text-1-3') }</p>
                          <p>{ t('uvjeti.uvjeti-text-1-4') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                <hr className="line"/>

                  <div>
                  <Button id="opc2" className="morebtn" >{ t('uvjeti.uvjeti-title-2') }</Button>
                    <UncontrolledCollapse toggler="#opc2">
                      <Card>
                        <CardBody>
                          <p>{ t('uvjeti.uvjeti-text-2-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                <hr className="line"/>

                  <div>
                  <Button id="opc3" className="morebtn" >{ t('uvjeti.uvjeti-title-3') }</Button>
                    <UncontrolledCollapse toggler="#opc3">
                      <Card>
                        <CardBody>
                          <p>{ t('uvjeti.uvjeti-text-3-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                <hr className="line"/>

                  <div>
                  <Button id="opc4" className="morebtn" >{ t('uvjeti.uvjeti-title-4') }</Button>
                    <UncontrolledCollapse toggler="#opc4">
                      <Card>
                        <CardBody>
                          <p>{ t('uvjeti.uvjeti-text-4-1') }</p>
                          <p>{ t('uvjeti.uvjeti-text-4-2') }</p>
                          <p>{ t('uvjeti.uvjeti-text-4-3') }</p>
                          <p>{ t('uvjeti.uvjeti-text-4-4') }</p>
                          <p>{ t('uvjeti.uvjeti-text-4-5') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                <hr className="line"/>

                  <div>
                  <Button id="opc5" className="morebtn" >{ t('uvjeti.uvjeti-title-5') }</Button>
                    <UncontrolledCollapse toggler="#opc5">
                      <Card>
                        <CardBody>
                          <p>{ t('uvjeti.uvjeti-text-5-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                <hr className="line"/> 

                  <div>
                  <Button id="opc6" className="morebtn" >{ t('uvjeti.uvjeti-title-6') }</Button>
                    <UncontrolledCollapse toggler="#opc6">
                      <Card>
                        <CardBody>
                          <p>{ t('uvjeti.uvjeti-text-6-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>

                <hr className="line"/>
                
                <div>
                  <Button id="rez1" className="morebtn">{ t('uvjeti.uvjeti-title-7') }</Button>
                    <UncontrolledCollapse toggler="#rez1">
                      <Card>
                        <CardBody>
                        <p>{ t('uvjeti.uvjeti-text-7-1') }</p>
                        <ul>
                          <li>{ t('uvjeti.uvjeti-text-7-2') }</li>
                          <li>{ t('uvjeti.uvjeti-text-7-3') }</li>
                        </ul>
                        <p>{ t('uvjeti.uvjeti-text-7-4') }</p>
                        <p>{ t('uvjeti.uvjeti-text-7-5') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <hr className="line"/>

                <div>
                  <Button id="rez2" className="morebtn">{ t('uvjeti.uvjeti-title-8') }</Button>
                    <UncontrolledCollapse toggler="#rez2">
                      <Card>
                        <CardBody>
                        <p>{ t('uvjeti.uvjeti-text-8-1') }</p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>

                <hr className="line"/>

                <div>
                  <Button id="rez3" className="morebtn">{ t('uvjeti.uvjeti-title-9') }</Button>
                    <UncontrolledCollapse toggler="#rez3">
                      <Card>
                        <CardBody>
                        <p>{ t('uvjeti.uvjeti-text-9-1') }</p>
                        <p>{ t('uvjeti.uvjeti-text-9-2') }</p>
                        <ul>
                          <li>{ t('uvjeti.uvjeti-text-9-3') } Varaždinska 26, 21000 Split</li>
                          <li>{ t('uvjeti.uvjeti-text-9-4') } <a href="mailto:info@macitours.hr">info@macitours.hr</a></li>
                          <li>{ t('uvjeti.uvjeti-text-9-5') } <a href="tel:+385 91 225 5539">+385 91 225 5539</a></li>
                        </ul>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
               
              </div>
          </div>
    </div>
  );
}

export default Uvjeti;

