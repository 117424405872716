import React from 'react';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import Kontaktizleti  from './kontaktizleti.js';

import { useTranslation } from 'react-i18next';

const Medugorje = (props) => {

const { t } = useTranslation();

  return (
  	<div>
      
            <div className="container"> 
            <div>
              <img className="logo" src="slike/logo.png" alt="logo"/>
          </div>
              <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="slike/izleti/izleti/medugorje1.jpg" alt="First slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="slike/izleti/izleti/medugorje2.jpg" alt="Second slide"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only"></span>
                </a>
              </div>
              <div className="text">
                <h2 className="h2izlet">{ t('medugorje.medugorje-title') }</h2>
                <div>
                  <Button id="opcenito" className="morebtn" >{ t('medugorje.medugorje-Overview-title') }+</Button>
                    <UncontrolledCollapse toggler="#opcenito">
                      <Card>
                        <CardBody>
                          <p>{ t('medugorje.medugorje-Overview-text') } </p>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  </div>
                <hr className="line"/>
                <div>
                  <Button id="ukljuceno" className="morebtn">{ t('medugorje.medugorje-title-2') }</Button>
                    <UncontrolledCollapse toggler="#ukljuceno">
                      <Card>
                        <CardBody>
                <ul>
                  <li>{ t('medugorje.medugorje-list-1') }</li>
                  <li>{ t('medugorje.medugorje-list-2') }</li>
                  <li>{ t('medugorje.medugorje-list-3') }</li>
                  <li>{ t('medugorje.medugorje-list-4') }</li>
                  <li>{ t('medugorje.medugorje-list-5') }</li>
                  <li>{ t('medugorje.medugorje-list-6') }</li>
                  <li>{ t('medugorje.medugorje-list-7') }</li>
                  <li>{ t('medugorje.medugorje-list-8') }</li>
                </ul>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>
                <div>
                  <Button id="odlazak" className="morebtn">{ t('medugorje.medugorje-title-3') }</Button>
                    <UncontrolledCollapse toggler="#odlazak">
                      <Card>
                        <CardBody>
                          <p>{ t('medugorje.medugorje-text-2') }
                          </p>
                          </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <div>
                  <Button id="itinerar" className="morebtn">{ t('medugorje.medugorje-title-4') }</Button>
                    <UncontrolledCollapse toggler="#itinerar">
                      <Card>
                        <CardBody>
                          <p>{ t('medugorje.medugorje-itinerar-txt-1') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-2') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-3') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-4') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-5') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-6') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-7') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-8') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-9') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-10') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-11') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-12') }</p>
                          <p>{ t('medugorje.medugorje-itinerar-txt-13') }</p>
                        </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <div>
                  <Button id="otkazivanje" className="morebtn">{ t('medugorje.medugorje-title-6') }</Button>
                    <UncontrolledCollapse toggler="#otkazivanje">
                      <Card>
                        <CardBody>
                           <p>
                          { t('medugorje.medugorje-cancel-txt') }
                          </p>
                        </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
                <hr className="line"/>

                <Kontaktizleti izlet="Međugorje"/>

              </div>
          </div>
    </div>
  );
}

export default Medugorje;

